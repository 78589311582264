import { Button, Checkbox, Form, Input, message } from "antd";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import React from "react";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { LoginRespone, LOGIN_MUTATION, GET_USER } from "../../graphql/users";

const Login: React.FC = () => {
  const { authLogin, userInfo, setUserInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [login] = useMutation<LoginRespone, any>(LOGIN_MUTATION);
  useQuery(GET_USER, {
    onCompleted(data) {
      setUserInfo && setUserInfo(data.currentUser);
    },
    onError(error) {
      console.error("error", error);
    },
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo]);

  const onFinish = async (values: {
    phoneNumber: string;
    password: string;
  }) => {
    try {
      await login({
        variables: {
          phoneNumber: values.phoneNumber,
          password: values.password,
        },
        onCompleted(data) {
          if (data) {
          // if (data && (data.login.user.isAdmin || data.login.user.isSuperAdmin)) {
            authLogin(data.login);
          }
          else {
            form.setFields([
              {
                name: "password",
                errors: ["Không có quyền truy cập"],
              },
            ]);
          }
        },
      });
    } catch (error: any) {
      const errMsg = error.message;
      form.setFields([
        {
          name: "password",
          errors: [errMsg],
        },
      ]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Beled - Đăng nhập</title>
      </Helmet>
      <div className="min-h-[100vh] flex justify-center">
        <div
          className="flex-auto bg-blue-d90 h-[100vh] bg-center bg-cover"
          style={{
            backgroundImage: "url(../images/login_bg.jpg)",
          }}
        ></div>
        <div className="p-[32px] sm:p-[64px] max-w-5xl mx-auto w-full sm:w-[500px] flex-shrink-0 pt-[128px]">
          <div className=" text-[32px] font-semibold mb-[32px] text-center">
            Đăng nhập
          </div>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="my-[8px] sm:my-[24px]"
              labelAlign="left"
              label={"Số điện thoại"}
              name="phoneNumber"
              rules={[{ required: true, message: "Tối thiểu 4 kí tự", min: 4 }]}
            >
              <Input autoComplete="phoneNumber"/>
            </Form.Item>

            <Form.Item
              className="my-[8px] sm:my-[24px]"
              labelAlign="left"
              label="Mật khẩu"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Mật khẩu phải trên 6 kí tự",
                  min: 6,
                },
              ]}
            >
              <Input.Password autoComplete="current-password" />
            </Form.Item>

            {/* <Form.Item
              wrapperCol={{ span: 24 }}
              className="my-[8px] sm:text-right"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox className="w-full sm:w-[70%]">Remember me</Checkbox>
            </Form.Item> */}
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full my-[24px] sm:my-[0px] sm:w-[35%] sm:ml-[30%] "
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;

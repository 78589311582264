import { Button, Form, Input, message } from "antd";
import { Helmet } from "react-helmet";
import * as React from "react";
import { useMutation } from "@apollo/client";
import {
  ChangePasswordInput,
  CHANGE_PASSWORD_USER,
} from "../../../../graphql/users";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

interface IChangePasswordProps {}

const ChangePassword: React.FunctionComponent<IChangePasswordProps> = () => {
  const [changePassword, { loading }] = useMutation<
    Boolean,
    ChangePasswordInput
  >(CHANGE_PASSWORD_USER);
  const { logout } = React.useContext(AuthContext);
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const onFinish = async ({
    password,
    newPassword,
    confirmPassword,
  }: ChangePasswordInput) => {
    try {
      await changePassword({
        variables: {
          password,
          newPassword,
          confirmPassword,
        },
      });
      message.success("Đổi mật khẩu thành công. Vui lòng đăng nhập lại");
      navigation("/login");
      setTimeout(() => logout(), 500);
    } catch (error: any) {
      const errMsg = error.toString().replace("Error: ", "");
      form.setFields([
        {
          name: "password",
          errors: [errMsg],
        },
      ]);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Beled - Đổi mật khẩu</title>
      </Helmet>
      <div className=" flex-auto flex justify-center px-[24px] pt-[88px]">
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="w-full md:w-[700px] shrink-0"
          name="basic"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Mật khẩu hiện tại"
            name="password"
            rules={[
              { required: true, min: 6, message: "Mật khẩu trên 6 kí tự" },
            ]}
          >
            <Input.Password
              placeholder="Nhập mật khẩu hiện tại"
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            hasFeedback
            rules={[
              {
                required: true,
                min: 6,
                message: "Mật khẩu phải trên 6 kí tự",
              },
            ]}
          >
            <Input.Password
              placeholder="Nhập mật khẩu mới"
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            label="Xác nhận mật khẩu"
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Thiếu xác nhận mật khẩu",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mật khẩu xác nhận không khớp")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Xác nhận mật khẩu mới"
              className="w-full"
            />
          </Form.Item>

          <div className="w-full text-right">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-[150px]"
            >
              Đổi mật khẩu
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;

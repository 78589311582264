import {
  ApiOutlined,
  BellFilled,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  notification,
} from "antd";
import * as React from "react";
import { NavLink, NavLinkProps, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Notification from "../../pages/components/Notification";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  GET_ACTIVE_NOTIFICATIONS_COUNT,
  GET_NOTIFICATIONS_SUBSCRIPTION,
  MARK_NOTIFICATIONS_LOADED,
} from "../../graphql/notification";
import { useLocation } from "react-router-dom";
import "./index.css";

interface IYHeaderProps {}

type NotificationType = "success" | "info" | "warning" | "error";

const openNotificationWithIcon = (type: NotificationType) => {
  notification[type]({
    message: "Có sự cố mới",
    duration: 3,
    placement: "bottomRight",
  });
};

const YHeader: React.FunctionComponent<IYHeaderProps> = (props) => {
  const { logout, userInfo } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [subcriptionNotifications, setSubcriptionNotifications] =
    React.useState<any>([]);
  const { refetch } = useQuery<any, any>(GET_ACTIVE_NOTIFICATIONS_COUNT, {
    fetchPolicy: "no-cache",
    onCompleted(rs) {
      setNotificationCount(rs.unloadedNotificationsCount);
      // setNotifications(rs.activeNotifications);
    },
  });

  const [markNotificationLoaded] = useMutation(MARK_NOTIFICATIONS_LOADED);

  useSubscription(GET_NOTIFICATIONS_SUBSCRIPTION, {
    onSubscriptionData({ subscriptionData }) {
      // refetch();
      openNotificationWithIcon("warning");
      setSubcriptionNotifications(subscriptionData.data.notificationChange);
      setNotificationCount((pre) => pre + 1);
    },
  });

  const guestMenu: MenuProps["items"] = [
    {
      key: "1",
      label: <div>{userInfo?.phoneNumber}</div>,
      icon: <InfoCircleOutlined />,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            navigate("/change-password");
          }}
        >
          Đổi mật khẩu
        </div>
      ),
      icon: <EditOutlined />,
    },
    {
      key: "3",
      label: <div onClick={logout}>Đăng xuất</div>,
      icon: <LogoutOutlined />,
    },
  ];

  const superAdminMenu: MenuProps["items"] = [
    {
      key: "1",
      label: <div>{userInfo?.phoneNumber}</div>,
      icon: <InfoCircleOutlined />,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            navigate("/change-password");
          }}
        >
          Đổi mật khẩu
        </div>
      ),
      icon: <EditOutlined />,
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            navigate("/users-management");
          }}
        >
          Quản lí người dùng
        </div>
      ),
      icon: <UsergroupAddOutlined />,
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => {
            navigate("/registerdevices");
          }}
        >
          Đăng kí tủ
        </div>
      ),
      icon: <ApiOutlined />,
    },
    {
      key: "5",
      label: <div onClick={logout}>Đăng xuất</div>,
      icon: <LogoutOutlined />,
    },
  ];

  const adminMenu: MenuProps["items"] = [
    {
      key: "1",
      label: <div>{userInfo?.phoneNumber}</div>,
      icon: <InfoCircleOutlined />,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            navigate("/change-password");
          }}
        >
          Đổi mật khẩu
        </div>
      ),
      icon: <EditOutlined />,
    },
    {
      key: "5",
      label: <div onClick={logout}>Đăng xuất</div>,
      icon: <LogoutOutlined />,
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleNotificationClick = async () => {
    markNotificationLoaded();
    setShowNotification(true);
    setNotificationCount(0);
  };

  return (
    <>
      <div className="flex md:h-[7vh] items-center justify-between px-2 md:px-8 py-2 bg-variant-white shadow-lg z-50 relative">
        <div>
          <div
            className="bg-left w-[364px] h-[64px] bg-contain bg-no-repeat cursor-pointer"
            style={{
              backgroundImage: "url(../images/bl_logo2.jpg)", //Quasal
            }}
            onClick={() => navigate("/")}
          ></div>
          {/* <LogoDark className="w-[207px]"></LogoDark> */}
        </div>
        <div className="text-blue-d30 py-2 justify-end font-semibold flex items-baseline gap-[16px] w-[300px]">
          <div onClick={handleNotificationClick}>
            <Badge count={notificationCount}>
              <Avatar
                className="cursor-pointer"
                size="default"
                icon={<BellFilled />}
              />
            </Badge>
          </div>
          <Dropdown
            menu={
              userInfo?.isSuperAdmin
                ? { items: superAdminMenu }
                : userInfo?.isAdmin
                  ? { items: adminMenu }
                  : { items: guestMenu }
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Avatar size="default" icon={<UserOutlined />} />
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
        <Modal
          title="Thông báo"
          className="noti-modal absolute mx-auto right-2 md:right-4 top-12 h-[90%] overflow-auto rounded-2xl shadow-xl"
          open={showNotification}
          centered={false}
          footer={false}
          // closable={false}
          mask={false}
          // onOk={() => setShowNotification(false)}
          onCancel={() => setShowNotification(false)}
        >
          <Notification subscriptionData={subcriptionNotifications} />
        </Modal>
      </div>
    </>
  );
};

export default YHeader;
interface IYNavLink extends NavLinkProps {
  children: React.ReactNode;
}
const YNavLink: React.FC<IYNavLink> = ({ children, ...props }) => {
  return (
    <NavLink
      className={({ isActive }) => {
        const isActiveClass = isActive
          ? " text-blue-primary"
          : " text-variant-black";
        return `h-[40px] leading-[40px] block rounded-[4px] overflow-hidden transform duration-500 hover:text-blue-primary ${isActiveClass}`;
      }}
      {...props}
    >
      {children}
    </NavLink>
  );
};
